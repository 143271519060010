import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"

import { Router } from "@reach/router"

import "pure-react-carousel/dist/react-carousel.es.css"

const Blog = (props) => (
  <>
    <SEO title="Home" />
    <div className="bg-[url('/images/bot-bg.png')] bg-cover p-[9rem] lg:mt-[-3rem]">
      <h2 className="text-center teamtitle text-white">Blog</h2>
      <br />

      <p className="text-center teamp text-white">
     
      </p> 

      <div className="flex items-center justify-center">
        <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
          Explore More
        </button>
      </div>
    </div>
    <Container className="mb-[5rem]">
      <div className="grid lg:grid-cols-3 sm:grid-cols-2  gap-14 mt-21 place-items-center ">
        <div className="items-end col-span-2">
          <img
            className="blogimg "
            src="/images/mask.png"
            alt="Sunset in the mountains"
          />
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white hm40 border border-blue-500 hover:border-transparent rounded">
            Mortage
          </button>
          <h1 className="">
          {props.slug}
          </h1>
          <p className="text-gray-700 text-base mtr1">
          {props.content}
          </p>
        </div>
        <div className="items-end">
          <div className="search float-right]">
            <span className="fa fa-search"></span>
            <input className="bloginput" placeholder="Search term" />
          </div>
          <br />

          <br />
          <div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
            <a href="#">
              <img className="rounded-t-lg" src="/images/tech.png" alt="" />
            </a>
            <div className="p-5">
              <a href="#">
                <h5 className="hm36">Business Unit</h5>
              </a>
              <p className="hm32">
                View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </>
)

const IndexPage = props => (
  <Layout>
    <Router>
      
      <Blog path="/read-blog/:slug" />
     
    </Router>
  </Layout>
)

export default IndexPage
